import { useDispatch, useSelector } from 'react-redux'
import { deleteAssetsAccreditation, getInvestorStatus } from 'slices/investorStatusSlice'
import { images } from 'assets'
import { useEffect } from 'react'
import PlaidAssetsLink from '../partials/PlaidAssetsLink'
import { Mixpanel, useLDFlags } from 'utils'
import { Button } from 'components'

const PlaidAssetsRevamp = ({ handleSubmitPlaid, loading }) => {
  const dispatch = useDispatch()
  const { assetsAccounts, deleteAssetsLoading, isProcessing } = useSelector((state) => state.investorStatusSlice)
  const { restrictedAccreditationOptions } = useLDFlags(['restrictedAccreditationOptions'])
  const disabled = deleteAssetsLoading || isProcessing || assetsAccounts?.length === 0
  

  useEffect(() => {
    // refresh page when linked accounts are removed
  }, [deleteAssetsLoading])

  const handleRemoveAccounts = async () => {
    Mixpanel.track('Click Remove Connected Banks on Accreditation Page')
    await dispatch(deleteAssetsAccreditation())
    dispatch(getInvestorStatus('noRefresh'))
  }

  return (
    <>
      <div className='verification-section'>
        <div className='large_1 space-below-sm'>Connect Bank Accounts</div>
        <p className='medium_1'>Please connect the bank accounts necessary to validate the accreditation status {restrictedAccreditationOptions ? 'you\'ve chosen' : 'chosen above'}. When you are done connecting all of your bank accounts, please click “submit” below.</p>
        {assetsAccounts?.length > 0 && <a className={`remove-account-btn link ${disabled ? 'gray' : 'primary'} ${disabled ? 'disabled' : ''}`} onClick={handleRemoveAccounts} >
          <img className='trashcan' src={disabled ? images['trashcan-gray'] : images.trashcan} alt='Delete Account' />
        Remove connected banks
        </a>}
        <div>{assetsAccounts && assetsAccounts.map(assetAccount =>
          <div className='connected-bank-account medium_2' key={assetAccount.accountMask}>
            <img className='bank-icon' src={disabled ? images['plaid-account-pending'] : images['plaid-account-active']} alt={assetAccount.institutionName} />
            {assetAccount.institutionName} (*{assetAccount.accountMask})
          </div>)}
        </div>
        <PlaidAssetsLink hasLinked={assetsAccounts?.length > 0}/>
        <div className='md:inline-flex'>
          <Button loading={loading} disabled={disabled} onClick={handleSubmitPlaid} type='button'>
            Submit
          </Button>
        </div>
      </div>
    </>
  )
}

export default PlaidAssetsRevamp
