import Checkbox from 'components/Global/Inputs/Checkbox'
import { useLDFlags } from 'utils'

const RememberMeCheckbox = () => {
  const { securitySignInEnhancement } = useLDFlags(['securitySignInEnhancement'])
  
  const getAuthText = (isEnhanced) => {
    if (isEnhanced === undefined) return { label: '', tooltip: '' };
    
    return {
      label: isEnhanced ? 'Stay signed in' : 'Remember Me',
      tooltip: isEnhanced ? 'Keeps you signed in for 30 days. Select only if device is not shared.' : ''
    };
  };
  
  const { label, tooltip } = getAuthText(securitySignInEnhancement)

  return (
    <Checkbox
      label={label}
      name='rememberMe'
      ariaLabel='rememberMe'
      checkboxClass='remember-me-checkbox'
      content={tooltip}
    />
  )
}

export default RememberMeCheckbox
