import { App, GoogleRecaptcha } from './components'
import './assets/scss/style.scss'
import { Provider } from 'react-redux'
import {
  Router
} from 'react-router-dom'
import history from './history'
import * as Sentry from '@sentry/react'
import { store } from './store'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { createRoot } from 'react-dom/client'

const env = {
  'www.linqto.com': 'production',
  'app.linqto.com': 'production',
  'stage.linqto.com': 'stage',
  'beta.linqto.com': 'beta',
  'app.beta.linqto.com': 'beta',
  'alpha.linqto.com': 'alpha',
  'app.alpha.linqto.com': 'alpha',
  'app.growth.linqto.com': 'growth',
  'app.growth.linqto.io': 'growth',
  'app.team-ai.linqto.io': 'team-ai',
  'app.trade.linqto.com': 'trade',
  'app.trade.linqto.io': 'trade',
  'dev.linqto.com': 'dev',
  'dev.linqto.io': 'dev',
  'app.dev.linqto.com': 'dev',
  'app.dev.linqto.io': 'dev'
}
// NOTE:init and configure sentry.io: https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#attach-stacktrace
if (env[window.location.host] !== 'dev') {
  Sentry.init({
    dsn: 'https://c3ef68580daf4804936dd7d14b6bc746@o1209309.ingest.sentry.io/6348375',
    integrations: [
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: false
      })
    ],
    environment: env[window.location.host],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0,
    sampleRate: 0.5,
    attachStacktrace: true,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      'LaunchDarklyTimeoutError',
      // Facebook borked
      'fb_xd_fragment',
      // ISP 'optimizing' proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      'ttd_dom_ready is not defined',
      // this is a sentry default error
      't.apply is not a function'
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // Ignore Google flakiness
      /\/(gtm|ga|analytics)\.js/i
    ]
  })
}

const googleClientId = env[window.location.host] === 'dev' ? '1964738591-0upb2a0nh0bc1vqnpbhbqrl1kd5losor.apps.googleusercontent.com' : '268198152363-llf1st1jhvnq1gg4r14u54jmf3ajh3b5.apps.googleusercontent.com'

const container = document.getElementById('root')
const app = createRoot(container)

app.render(
  <Provider store={store}>
    <Router history={history}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <GoogleRecaptcha>
          <App />
        </GoogleRecaptcha>
      </GoogleOAuthProvider>
    </Router>
  </Provider>
)
