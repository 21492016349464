
import { useSelector } from 'react-redux'

const InvestmentNoData = ({ tab }) => {
  const { cashPaymentEnabled, needToCompleteInvestorProfile, totalCashAvailable } = useSelector(state => state.portfolioSlice)

  const emptyStateMainText = 'You haven\'t made any investments yet.'
  let emptyStateSecondaryText = 'Start Investing today in one of our exciting opportunities.'

  if (cashPaymentEnabled) {
    if (needToCompleteInvestorProfile) {
      emptyStateSecondaryText = 'Complete your profile to start investing.'
    }
    if (!needToCompleteInvestorProfile && totalCashAvailable === 0) {
      emptyStateSecondaryText = 'Add funds to your account to start investing.'
    }
  }

  return (
    <div className='flex-col justify-start my-10 lg:min-h-[40vh] min-h-[30vh]'>
      {tab === 'history' && <p className='large_1'>Order History</p>}
      <div>
        <p className='large_1 text-gray3'>{emptyStateMainText}</p>
        <p className='medium_1 text-gray2'>{emptyStateSecondaryText}</p>
      </div>
    </div>
  )
}

export default InvestmentNoData
