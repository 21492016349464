import RadioButton from 'components/Global/Inputs/RadioButton'
import { useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { saveAccreditedInvestorStatusChoice, getInvestorStatus } from 'slices/investorStatusSlice'
import UKResidentModal from './UKResidentModal'
import { Button } from 'components'
import { ukResidentModalDetails } from '../../data'
import { Mixpanel } from 'utils'
import AccreditationHeader from './AccreditationHeader'
import EditProfileDetailsButton from './EditProfileDetailsButton'

const UKResidentsSection = () => {
  const [show, setShow] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)

  const methods = useForm()
  const dispatch = useDispatch()
  const { watch, register, handleSubmit } = methods
  const choice = watch('choice')
  const investorOption = watch('investorOption')
  const confirmVerification = watch('confirm')

  const {
    accreditedCountry
  } = useSelector((state) => state.investorStatusSlice)

  useEffect(() => {
    if (choice === 'NOT_ACCREDITED') {
      setDisabled(false)
    }

    if (investorOption && confirmVerification) {
      setDisabled(false)
    }

    if (investorOption && !confirmVerification && choice !== 'NOT_ACCREDITED') {
      setDisabled(true)
    }
  }, [choice, investorOption, confirmVerification])

  const onSubmit = async data => {
    setLoading(true)
    Mixpanel.track('Onboarding Click Submit Verification', { 'Accreditation Method': 'Auto', 'Accredited Country': accreditedCountry, 'Accreditation Status': investorOption })
    const res = await dispatch(saveAccreditedInvestorStatusChoice({ choice: data.investorOption }))
    const { meta } = res
    if (meta.requestStatus === 'fulfilled') {
      setLoading(false)
      await dispatch(getInvestorStatus())
    }
  }

  return (
    <>
      {show && <UKResidentModal hideModal={() => setShow(false)} data={ukResidentModalDetails.find(d => d.key === investorOption)} />}
      <FormProvider {...methods}>
        <form className='uk-residents-section' onSubmit={handleSubmit(onSubmit)}>
          <AccreditationHeader />
          <div className='large_1 acc-nav-title space-above-lg'>I am a self-certified investor <span className=''>(please choose one option):</span></div>
          <div className='radio-group radio-button-container'>
            <RadioButton name='investorOption' value='EXPERIENCED_INVESTOR' label='Sophisticated Investor (most common)' />
            <RadioButton name='investorOption' value='HIGH_NETWORTH' label='High Net Worth Individual' />
          </div>
          {investorOption && choice !== 'NOT_ACCREDITED' &&
          <>
            <div className='content-separator'></div>
            <div className='input-group checkbox'>
              <label className='medium_1 checkbox-label'>
                <input data-testid='checkbox' type='checkbox' {...register('confirm')} />
                <div className='checkmark' />
                <span className='medium_1 checkbox-label__text'>
                  I confirm I have read the&nbsp;<Button className='medium_2 checkbox-label__link' onClick={() => setShow(true)}>verification statement</Button>&nbsp;and I am qualified to invest.
                </span>
              </label>
            </div>
          </>
          }
          <div className='mt-8 md:inline-flex'>
            <EditProfileDetailsButton />
            <Button loading={loading} disabled={disabled} type='submit'>
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

export default UKResidentsSection
