import { UTMTracking } from 'utils'
/**
 * Mixpanel Singleton
 * - Intializes a Mixpanel instance
 * and has all the needed Mixpanel functions
 * to use Mixpanel
 */
const mixpanelAccessToken = {
  'localhost:3000': 'a5bb8650997dba8e3f392e5677465c6b',
  'dev.linqto.com': 'a5bb8650997dba8e3f392e5677465c6b',
  'dev.linqto.io': 'a5bb8650997dba8e3f392e5677465c6b',
  'dev.app.linqto.com': 'a5bb8650997dba8e3f392e5677465c6b',
  'app.dev.linqto.com': 'a5bb8650997dba8e3f392e5677465c6b',
  'app.dev.linqto.io': 'a5bb8650997dba8e3f392e5677465c6b',
  'alpha.linqto.com': 'f419f8aa4aec92a622adabb2653bb4e9',
  'alpha.app.linqto.com': 'f419f8aa4aec92a622adabb2653bb4e9',
  'app.alpha.linqto.com': 'f419f8aa4aec92a622adabb2653bb4e9',
  'app.growth.linqto.com': '56b56c16600adfd1e3f8d3129a58797f',
  'app.growth.linqto.io': '56b56c16600adfd1e3f8d3129a58797f',
  'app.team-ai.linqto.io': '990c6d7b6d4c6f51af8c45069fdae658',
  'app.trade.linqto.com': '42f6151d441d305c6a3f0512a49b53c3',
  'app.trade.linqto.io': '42f6151d441d305c6a3f0512a49b53c3',
  'beta.linqto.com': '5f97cb773971f89fb281dc81c65c6153',
  'app.beta.linqto.com': '5f97cb773971f89fb281dc81c65c6153',
  'beta.app.linqto.com': '5f97cb773971f89fb281dc81c65c6153',
  'www.linqto.com': '9520a6432633394d40b28aa63ca84020',
  'app.linqto.com': '9520a6432633394d40b28aa63ca84020'
}

let mixpanel = null

const Mixpanel = (() => {
  return {
    initialize: () => {
      mixpanel = require('mixpanel-browser')
      mixpanel.init(mixpanelAccessToken[window.location.host], {
        debug: false, // only flip this to true for debugging
        batch_requests: false
      })
    },
    register: (superProperties = {}) => {
      if (mixpanel) {
        mixpanel.register(superProperties)
      }
    },
    registerUTM: (utm = '') => {
      if (mixpanel) {
        UTMTracking(utm)
      }
    },
    track: (eventName, properties = {}) => {
      if (mixpanel) {
        mixpanel.track(eventName, properties)
      }
    },
    identify: (id, isMixpanelDistinctId = false) => {
      /* Note: converting id to string doesn't
         set the userId as distinctId in Mixpanel.
         As workaround, I added whitespace to string.
      */
      const userId = isMixpanelDistinctId ? id : id + ' '
      if (mixpanel) {
        mixpanel.identify(userId)
      }
    },
    setPeople: (properties) => {
      if (mixpanel) {
        mixpanel.people.set((properties))
      }
    },
    reset: () => {
      if (mixpanel) {
        mixpanel.reset()
      }
    },
    getDistinctId: () => {
      if (mixpanel) {
        return mixpanel.get_distinct_id()
      }
    }
  }
})()

export { Mixpanel }
