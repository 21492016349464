import { Modal, Row } from 'components'
import Button from 'components/Global/Button'
import ReactHtmlParser from 'react-html-parser'

const UKResidentModal = ({ hideModal, data: { title, body } }) => {
  return (
    <Modal
      crossToClose
      hideModal={hideModal}
      modalHeader={title}
      innerStyle='text-left'>
      <div className='modal-text'>{ReactHtmlParser(body)}</div>
      <Row className='flex justify-center'><Button onClick={hideModal}>Continue to Verify</Button></Row>
    </Modal>
  )
}

export default UKResidentModal
