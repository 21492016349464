import { useEffect } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useSelector, useDispatch } from 'react-redux'
import {
  getPlaidIncomeToken,
  getPlaidIncomeAccreditaiton,
  getInvestorStatus
} from 'slices/investorStatusSlice'
import { images } from 'assets'
import { Mixpanel } from 'utils'
import { useLDFlags } from 'utils/LaunchDarkly'

const PlaidIncomeLink = ({title, copy, icon, tags, testId, setShowPlaidIncomeFailure}) => {
  const dispatch = useDispatch()
  const { plaidAccreditation } = useLDFlags(['plaidAccreditation'])
  const { plaidIncomeToken } = useSelector((state) => state.investorStatusSlice)
  
  useEffect(() => {
    if (plaidAccreditation && !window.ReactNativeWebView) {
      dispatch(getPlaidIncomeToken())
    }
  }, [])

  const onLinkSuccess = async () => {
    const { payload } = await dispatch(getPlaidIncomeAccreditaiton())
    if (!payload) {
      Mixpanel.track('Onboarding Failed Accreditation', { 'Accredited Status': '$200k Individual Income for 2 Years', 'Accreditaiton Method': 'Plaid' })
      setShowPlaidIncomeFailure(true)
    }
    dispatch(getInvestorStatus('noRefresh'))
  }

  const onExit = async (error) => {
    console.log('Plaid error', error)
  }
  const onEvent = async (eventName, metadata) => {
    if (eventName === 'ERROR') {
      Mixpanel.track('Accreditation Plaid Income Event Error', { Error: metadata.error_code })
    } else {
      Mixpanel.track(`Accreditation Plaid Income Event: ${eventName}`, { 'Link Step': metadata.view_name })
    }
  }
  const config = {
    onSuccess: onLinkSuccess,
    onExit,
    onEvent,
    token: plaidIncomeToken
  }

  const { open } = usePlaidLink(config)

  const connectPlaid = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('triggerPlaidIncome')
    } else {
      open()
    }
  }

  if (!plaidAccreditation) {
    return null
  }
  
  return (
    <div className='acc-nav-item' onClick={connectPlaid} data-testid={testId} role='button' aria-label={title} tabIndex={0}>
      <img src={images[icon]} alt={title} />
      <div className='acc-nav-copy'>
        <div className='medium_2 gray5'>{title}</div>
        <div className='medium_1 gray3'>{copy}</div>
        {tags?.length > 0 && <div className='acc-nav-tags'>
          {tags?.map((tag, i) => {
            return (
              <div key={i} className={`acc-nav-tag ${tag?.type}`}>
                {tag?.copy}
              </div>
            )
          })}
        </div>}
      </div>
      <img src={images.arrow_forward_ios} alt={`Go to ${title}`} className='acc-nav-arrow' />
    </div>
  )
}

export default PlaidIncomeLink
