import { useState } from 'react'
import { Button, TextInput } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from 'schemas/requestFinancialadvisorSchema'
import { requestFinancialAdvisor, getInvestorStatus } from 'slices/investorStatusSlice'
import SubmitSuccessModal from './SubmitSuccessModal'
const FinancialAdvisor = () => {
  const dispatch = useDispatch()
  const {
    accreditedStatus,
    hasRequestedFinancialAdvisor
  } = useSelector((state) => state.investorStatusSlice)
  const methods = useForm({ mode: 'onSubmit', resolver: yupResolver(schema) })
  const { handleSubmit, reset } = methods
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const onSubmit = async (formValues) => {
    setSubmitLoading(true)
    await dispatch(requestFinancialAdvisor(formValues))
    setSubmitLoading(false)
    setSubmitSuccess(true)
    reset()
  }
  return (
    <>
      <div className='large_1'>
        Email a Financial Professional
      </div>
      <p className='medium_1 gray4 header-content'>
      Ask your third-party professional to prepare a simple letter on your behalf. We&apos;ll request the document for you and keep you copied on the message.
      </p>
      <FormProvider {...methods}>
        <form className='grid-old'>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name='name'
              label='Name'
              disabled={submitLoading}
              ariaLabel='Name of Advisor'
              placeholder='Name of Advisor'
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name='email'
              label='Email'
              disabled={submitLoading}
              ariaLabel='Email of Advisor'
              placeholder='Email of Advisor'
            />
          </div>
          <div className='column sixteen sixteen-mobile'>
            <Button loading={submitLoading} disabled={
              submitLoading || submitSuccess || accreditedStatus === 'UNDER_REVIEW'
            } onClick={handleSubmit(onSubmit)} >
              {hasRequestedFinancialAdvisor ? 'Request Sent - Resend' : 'Request'}
            </Button>
          </div>
        </form>
      </FormProvider>
      {submitSuccess && <SubmitSuccessModal hideModal={async () => { setSubmitSuccess(false); await dispatch(getInvestorStatus()) }} header='Request Financial Professional'>
        <div className='medium_1 content'>Email successfully sent! We’ll contact you after receiving the letter from your third-party professional.</div>
      </SubmitSuccessModal>}
    </>
  )
}

export default FinancialAdvisor
