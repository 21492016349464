import Modal from 'components/Global/BaseModal'
import Button from 'components/Global/Button'

const VerifyAccreditedStatusModal = ({ handleClose, handleClick }) => {
  return (
    <Modal modalHeader='Verify Your Accredited Investor Status' clickToClose crossToClose hideModal={handleClose}>
      <div className='flex-col items-center justify-center'>
        <span className='mb-6 medium_1'>To continue accessing investment opportunities on Linqto, we now require all users to verify their accredited investor status according to U.S. SEC criteria. This verification step helps us maintain regulatory compliance and protect your interests as an investor.</span>
        <Button onClick={handleClick}>Verify Your Accreditation</Button>
      </div>
    </Modal>
  )
}

export default VerifyAccreditedStatusModal
