import { useEffect, useState } from 'react'
import ResetModal from '../ResetModal/ResetModal'
import Button from '../../Global/Button'
import MfaModal from '../MfaModal'
import { seoTitleTemplate, Mixpanel, Fullstory } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { FormProvider, useForm } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'
import PasswordInput from 'components/Global/Inputs/PasswordInput'
import { useDispatch, useSelector } from 'react-redux'
import { clearErrorState, getCoreUser, signIn, signOut } from 'slices/userSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from 'schemas/signInSchema'
import SignUpLink from '../SignUp/partials/SignUpLink'
import SignInOAuthBtns from '../SignUp/partials/SignInOAuthBtns'
import RememberMeCheckbox from './partials/RememberMeCheckbox'
import { useLDFlags } from 'utils'

const SignIn = ({ match }) => {
  const [loading, setLoading] = useState(false)
  const [showResetModal, setShowResetModal] = useState(false)
  const [showMfaModal, setShowMfaModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { errMsg, mfas } = useSelector(state => state.userSlice)
  const [didSubmit, setDidSubmit] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { securitySignInEnhancement } = useLDFlags(['securitySignInEnhancement'])

  const methods = useForm({
    resolver: yupResolver(schema)
  })
  const { handleSubmit } = methods

  useEffect(() => {
    // if user already open the MFA modal and attempt to refresh
    if (location.pathname === '/signin/mfas' && mfas) {
      setShowMfaModal(true)
    } else {
      Mixpanel.registerUTM()
      Mixpanel.register({ Platform: 'Web' })
      Mixpanel.track('View Sign In Page')
    }
  }, [])

  useEffect(() => () => dispatch(clearErrorState()), [])

  useEffect(() => {
    const shouldRememberMe = securitySignInEnhancement === false;
    methods.setValue('rememberMe', shouldRememberMe)
  }, [securitySignInEnhancement])

  const mfaDisplay = async (payload) => {
    if (payload !== 403) {
      if (payload.mfaRequired) {
        setShowMfaModal(true)
        history.push('/signin/mfas')
      } else {
        await dispatch(getCoreUser())
        if (localStorage?.getItem('ria-route')) {
          history.push(localStorage?.getItem('ria-route'))
        } else if (localStorage?.getItem('prev-route')) {
          history.push(localStorage?.getItem('prev-route'))
          localStorage?.removeItem('prev-route')
        } else {
          history.push('/products')
        }
      }
    }
  }
  const onSubmit = async data => {
    setLoading(true)
    Mixpanel.track('Click Sign In with Email', { 'Remember me': data.rememberMe })
    Fullstory.track('Click Sign In with Email', { remember_me: data.rememberMe })
    const { meta, payload } = await dispatch(signIn(data))
    setLoading(false)
    if (meta.requestStatus === 'fulfilled') {
      mfaDisplay(payload)
    }
    if (payload === 403) {
      methods.setError('email')
      methods.setError('password')
      setDidSubmit(true)
    }
  }

  const onOAuthSuccess = async(accessToken, type) => {
    let tokenToSend = {}
    if (type === 'Google') {
      tokenToSend = { googleAccessToken: accessToken }
    }
    if (type === 'Apple') {
      tokenToSend = { appleAccessToken: accessToken }
    }
    const { meta, payload } = await dispatch(signIn({ ...tokenToSend }))
    if (meta.requestStatus === 'fulfilled') {
      mfaDisplay(payload)
    }
  }
  
  const hideResetModal = () => {
    setShowResetModal(false)
  }

  /**
   * When MFA modal is rendered and we close it, sign user out (Nav depends on user login state)
   * After user successfully submits MFA, close modal and push to designated route
   * @param {boolean} toggle
   */
  const hideMfaModal = async (toggle = false) => {
    setShowMfaModal(false)
    if (toggle) {
      await dispatch(signOut())
      history.push('/signin')
    }
  }

  const handleForgotPassword = () => {
    setShowResetModal(true)
    Mixpanel.track('Click Forgot Password')
  }

  return (
    <>
      <FormProvider {...methods}>
        <SeoMeta title={seoTitleTemplate('Sign In')}>
          <link rel='canonical' href='https://app.linqto.com/signin' />
        </SeoMeta>
        <div className='my-[30px] mx-0 lg:mx-[25vw] lg:my-[87px] lg:py-5'>
          <div className='py-5 px-6 lg:px-0 lg:py-0 max-w-[436px] m-auto'>
            <h1 className='large_1'>Welcome Back!</h1>
            <SignInOAuthBtns onOAuthSuccess={onOAuthSuccess} type='signIn' />
            <form className='my-4' onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                name='email'
                label='Email'
                ariaLabel='Email'
                type='email'
                autoComplete='username'
                onChange={() => setDidSubmit(false)}
              />
              <PasswordInput
                name='password'
                label='Password'
                ariaLabel='Password'
                type={showPassword ? 'text' : 'password'}
                autoComplete='current-password'
                viewPassword={() => setShowPassword((prev) => !prev)}
                onChange={() => setDidSubmit(false)}
              />
              <div className='flex items-center justify-between'>
                <RememberMeCheckbox />
                <a role='button' tabIndex={0} aria-label='Forgot your password link' className='underline cursor-pointer text-gray4 hover:text-gray3' onClick={handleForgotPassword}>Forgot your password?</a>
              </div>
              {errMsg && <p className='medium_1 mt-2 !text-errorRed'>The email address or password you entered was incorrect. Please try again. If you created an account using Google or Apple, please sign in using the respective buttons. </p>}
              <div className='flex'>
                <Button
                  type='submit'
                  loading={loading}
                  disabled={loading || didSubmit}
                  customClass='full-width'
                >
                  Sign In
                </Button>
              </div>
              <SignUpLink />
            </form>
          </div>
          {showResetModal && <ResetModal hideModal={hideResetModal} />}
          {showMfaModal && <MfaModal match={match} hideModal={hideMfaModal} />}
        </div>
      </FormProvider>
    </>
  )
}

export default SignIn
