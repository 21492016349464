import React, { useState } from 'react'
import Tooltip from './Tooltip'

const TooltipLabel = ({ content, label, extraLabel = '', customClass = '', htmlFor = '', header='' }) => {
  const [showHover, setShowHover] = useState(false)
  return (
    <label htmlFor={htmlFor}>
      {content !== '' ? (
        <div style={{ cursor: 'pointer' }}>
          <span
            className={customClass || 'medium_1'}
            onMouseEnter={() => setShowHover(true)}
            onMouseLeave={() => setShowHover(false)}
          >
            {label}
          </span>
          <Tooltip
            content={content}
            showHover={showHover}
            setShowHover={setShowHover}
            header={header}
          />
        </div>
      ) : (
        <div className='medium_1'>
          {label}{extraLabel && <span className='medium_1 gray3 optional'>{extraLabel}</span>}
        </div>
      )}

    </label>
  )
}

export default TooltipLabel
