import { useEffect, useState } from 'react'
import 'rc-slider/assets/index.css'
import { Row } from 'components'
import { images } from 'assets'
import { formatDecimal, formatWholeNumber, getSliderValue, Mixpanel, Fullstory } from 'utils'
import LinqtoSlider from 'components/Global/LinqtoSlider'
import { useSelector } from 'react-redux'
// import InvestDetailOrderPill from './InvestDetailOrderPill'

const OrderDetails = ({ setAmount, setShares, shares, setSharePrice, totalSavings, setTotalSavings, setTotalTillNextTear, isPreferred, setIsPreferred, setTiers, name, discountPercent, setDiscountPercent, discountedAmount, setDiscountedAmount }) => {
  const { details } = useSelector(state => state.investSlice)
  const { discountTiers, sliderIncrement, sliderMinimum, sliderMaxIndex, offers } = details || {}
  const [sliderIndex, setSliderIndex] = useState(0)
  const [amountUsd, setAmountUsd] = useState(sliderMinimum)

  // Note - Initialize slider values on first render
  useEffect(() => {
    if (sliderIndex === 0) {
      updateSliderIndex(0)
    }
  }, [])

  // NOTE = This will be repurposed or removed (still not sure) for the new purchase flow changes
  // useEffect(() => {
  //   // Generate the quick buy list if feature flag exists and slider values exist
  //   if (quickBuyOption && sliderMaxIndex && offers && sliderMinimum && sliderIncrement) {
  //     const investArr = [...Array(sliderMaxIndex).keys()]
  //     const investAmounts = investArr.map((index) => getSliderValue({ offers, sliderMinimum, sliderIncrement }, index))
  //     setQuickBuyList(investAmounts)
  //   }
  // }, [sliderMaxIndex, offers, sliderMinimum, sliderIncrement, quickBuyOption])

  // useEffect(() => {
  //   // Call updateSliderIndex if quick buy is on
  //   if (selectedPurchaseIndex || selectedPurchaseIndex === 0) {
  //     updateSliderIndex(selectedPurchaseIndex, '')
  //   }
  //   if (quickBuyOption) {
  //     resetSelect()
  //   }
  // }, [selectedPurchaseIndex])

  // const resetSelect = () => {
  //   const selectElement = document?.querySelector('.invest-order-dropdown')
  //   if (selectElement) {
  //     if (selectedPurchaseIndex < 5) {
  //       selectElement.selectedIndex = 0
  //     }
  //   }
  // }

  // This is called when someone drags the slider AND when someone clicks the Plus/Minus buttons
  const updateSliderIndex = (index, type = '') => {
    // If it is out of bounds, don't allow it
    if (index < 0 || index > sliderMaxIndex) {
      return
    }

    const sliderVal = getSliderValue({ offers, sliderMinimum, sliderIncrement }, index)
    if (type !== '') {
      Mixpanel.track('Click Slider', { Type: type, 'Company Name': name })
      Fullstory.track('Slider', { name: 'Purchase Order', company_name: name, type })
    }

    // Set the values
    setAmountUsd(sliderVal?.amount)
    setAmount(sliderVal?.amount)
    setSliderIndex(index)
    setShares(sliderVal?.shares)
    setSharePrice(sliderVal?.sharePrice)
    setTotalTillNextTear(sliderVal?.totalTillNextTier)
    setTotalSavings(sliderVal?.totalSavings)
    setIsPreferred(sliderVal?.isPreferred)
    setTiers(sliderVal?.tiers)

    const discount = discountPercent > 0 ? Math.floor((sliderVal?.amount * discountPercent) * 100) / 100 : 0
    setDiscountedAmount(discount)
    handleDiscountConversion(sliderVal)
  }

  const handleDiscountConversion = sliderVal => {
    const findCurrentDiscountTier = discountTiers?.filter((tier) => tier?.minimumPurchase < sliderVal?.amount)
    if (findCurrentDiscountTier && findCurrentDiscountTier.length === 1) {
      // If discount percent exists multiple by sliderAmount
      // otherwise set as discountAmount
      if (findCurrentDiscountTier?.[0]?.percent) {
        setDiscountedAmount(Math.floor((sliderVal?.amount * findCurrentDiscountTier?.[0]?.percent) * 100) / 100)
        setDiscountPercent(findCurrentDiscountTier?.[0]?.percent)
      } else if (findCurrentDiscountTier?.[0]?.amount) {
        setDiscountedAmount(Math.floor(findCurrentDiscountTier[0]?.amount * 100) / 100)
        setDiscountPercent(0)
      } else {
        setDiscountedAmount(0)
        setDiscountPercent(0)
      }
    } else if (findCurrentDiscountTier && findCurrentDiscountTier.length > 1) {
      const newDiscountTier = findCurrentDiscountTier[findCurrentDiscountTier.length - 1]
      // If discount percent exists multiple by sliderAmount
      // otherwise set as discountAmount
      if (newDiscountTier?.percent) {
        setDiscountedAmount(Math.floor((sliderVal?.amount * newDiscountTier?.percent) * 100) / 100)
        setDiscountPercent(newDiscountTier?.percent)
      } else if (newDiscountTier?.amount) {
        setDiscountedAmount(Math.floor(newDiscountTier?.amount * 100) / 100)
        setDiscountPercent(0)
      } else {
        setDiscountedAmount(0)
        setDiscountPercent(0)
      }
    } else {
      setDiscountedAmount(0)
      setDiscountPercent(0)
    }
  }

  let savingsCopy = ''

  if (isPreferred) {
    savingsCopy = <div className='invest-order-row__tier-price__copy-container'>
      <img src={images['team-apollo-black']} height={16} width={16} alt='Team Apollo Savings' />
      <span>Team Apollo Savings</span>
    </div>
  } else {
    savingsCopy = <span>Tiered Pricing Discount</span>
  }

  const plusBtnDisabled = sliderIndex >= sliderMaxIndex
  const minusBtnDisabled = sliderIndex <= 0

  return (
    <div className='box invest-detail-box invest-order-box' style={{ marginTop: '16px' }}>
      <Row className='invest-order-row amounts' style={{
        marginBottom: '14px'
      }}>
        <span>Shares to Purchase</span>
        <span className='medium_2'>{formatWholeNumber(shares)}</span>
      </Row>
      {/* NOTE - To be repurposed for new purchase flow  
        NEW LD FLAG - customInputPurchasePrice
      */}
      {/* <ConditionalRender isVisible={quickBuyOption}>
        <ConditionalRender isVisible={quickBuyList?.length > 0}>
          <Row className={'invest-order-row buy-order-row__quick-buy-options'}>
            <InvestDetailOrderPill onClick={() => setSelectedPurchaseIndex(0)} active={selectedPurchaseIndex === 0} amount={formatDecimal(quickBuyList[0]?.amount)} />
            {quickBuyList[1]?.amount && <InvestDetailOrderPill onClick={() => setSelectedPurchaseIndex(1)} active={selectedPurchaseIndex === 1} amount={formatDecimal(quickBuyList[1]?.amount)} />}
            {quickBuyList[2]?.amount && <InvestDetailOrderPill onClick={() => setSelectedPurchaseIndex(2)} active={selectedPurchaseIndex === 2} amount={formatDecimal(quickBuyList[2]?.amount)} />}
          </Row>
          <Row className='invest-order-row buy-order-row'>
            {quickBuyList[3]?.amount && <InvestDetailOrderPill onClick={() => setSelectedPurchaseIndex(3)} active={selectedPurchaseIndex === 3} amount={formatDecimal(quickBuyList[3]?.amount)} />}
            {quickBuyList[4]?.amount && <InvestDetailOrderPill onClick={() => setSelectedPurchaseIndex(4)} active={selectedPurchaseIndex === 4} amount={formatDecimal(quickBuyList[4]?.amount)} />}
            {quickBuyList[5]?.amount && <select className={`invest-order-dropdown ${selectedPurchaseIndex > 4 ? 'active' : ''}`} onChange={(e) => setSelectedPurchaseIndex(e.target.selectedIndex - 1)} aria-label='Other Shares to Purchase'>
              <option value='' disabled selected>Other</option>
              {quickBuyList.map((option, index) => (
                <option key={index} value={option.amount}>{formatDecimal(option.amount)}</option>
              ))}
            </select>}
          </Row>
        </ConditionalRender>
      </ConditionalRender> */}
      <Row className='invest-order-row slider-row'>
        <LinqtoSlider
          min={0}
          max={sliderMaxIndex}
          step={1}
          defaultValue={0}
          onChange={(val) => updateSliderIndex(val)}
          onAfterChange={() => Mixpanel.track('Click Slider', { Type: 'Slider', 'Company Name': name })}
          value={sliderIndex}
          onMinusBtnClick={() => updateSliderIndex(sliderIndex - 1, 'Minus')}
          onPlusBtnClick={() => updateSliderIndex(sliderIndex + 1, 'Plus')}
          disabled={false}
          plusBtnDisabled={plusBtnDisabled}
          minusBtnDisabled={minusBtnDisabled}
        />
      </Row>
      <Row className='invest-order-row__tier-price'>
        <div className='invest-order-row__tier-price__left'>
          {savingsCopy}
        </div>
        <span className={`invest-order-row__tier-price__right ${totalSavings > 0 ? 'red' : ''}`}>{totalSavings > 0 && '-'}{formatDecimal(totalSavings)}</span>
      </Row>
      {(discountPercent > 0 || discountedAmount > 0) && <Row className='invest-order-row__tier-price'>
        <div className='invest-order-row__tier-price__left'>Promo Discount</div>
        <span className='invest-order-row__tier-price__right red'>
          {discountPercent > 0 && `-${formatDecimal(discountPercent * 100, false, 0)}% (${formatDecimal(discountedAmount)})`}
          {!discountPercent && discountedAmount > 0 && `-${formatDecimal(discountedAmount)}`}
        </span>
      </Row>}
      <Row className='invest-order-row amounts'>
        <span>Amount in USD</span>
        <span className='medium_2'>{formatDecimal(amountUsd)}</span>
      </Row>
    </div>
  )
}

export default OrderDetails
