import { useState } from 'react'
import Tooltip from 'components/Global/Tooltip'
import ProgressBarRevamp from 'components/InvestorStatus/partials/ProgressBarRevamp'
import { MOBILE_WIDTH_SIZE, useLDFlags } from 'utils'
import NewProgressBar from './NewProgressBar'
import useWindowSize from 'hooks/useWindowSize'

const content = 'Your legal name is used for identity verification, tax reporting, and other legal documents. Contact support for questions.'

const ProfileHeader = ({
  email,
  legalName,
  isAccredited,
  hasKyc,
  isRetailUser,
  activeStep
}) => {
  const { width } = useWindowSize()
  const [show, setShow] = useState(false)
  const { suitabilityQuestions } = useLDFlags([ 'suitabilityQuestions'])
  return (
    <div className='header-progressbar-wrapper'>
      <h1 className='subinfo'>
        Investor Profile
        {isAccredited && <div className='medium_1 web-account-email'>
          <span>
            Account Email: <span className='profile-email'>{email}</span>
          </span>
          <br />
          <span>
            Legal Name: <span className='profile-email'>{legalName}</span>
          </span>
          <Tooltip
            content={content}
            showHover={show}
            setShowHover={setShow}
            configurable={false}
          />
        </div>}
      </h1>
      {width >= MOBILE_WIDTH_SIZE && hasKyc && !isAccredited && !activeStep && <p className='medium_1'>We've successfully verified your identity. Please review and/or complete the following information before continuing.</p>}
      {(hasKyc && !isAccredited && !isRetailUser) && <>
      
        {suitabilityQuestions ? <NewProgressBar activeStep={activeStep} /> :  <ProgressBarRevamp step={2} type={activeStep === 'basicInfo' ? 'half' : 'full'} />}
      </>}
      {width <= MOBILE_WIDTH_SIZE && hasKyc && !isAccredited && !activeStep && <p className='mb-8 medium_1'>We've successfully verified your identity. Please review and/or complete the following information before continuing.</p>}
      {(isAccredited || isRetailUser)&&
      <div className='mobile-account-email'>
        <span className='gray4'>
          Account Email: <span className='profile-email gray4'>{email}</span>
        </span>
        <br />
        <span className='gray4'>
          Legal Name: <span className='profile-email gray4'>{legalName}</span>
        </span>
        <Tooltip
          content={content}
          showHover={show}
          setShowHover={setShow}
        />
      </div>
      }
    </div>
  )
}

export default ProfileHeader
