import { Button } from 'components'
import { useSelector } from 'react-redux'
import PlaidIncomeLinkButton from './partials/PlaidIncomeLinkButton'
import PlaidIncomeDocumentLinkButton from './partials/PlaidIncomeDocumentLinkButton'

const ButtonGroup = ({ loading, disabled, onClick = () => {}, submitButtonType = '' }) => {
  const { hasRequestedFinancialAdvisor } = useSelector((state) => state.investorStatusSlice)

  const renderSubmitButton = () => {
    switch (submitButtonType) {
    case 'financialAdvisor' :
      return <Button loading={loading} disabled={disabled} onClick={onClick} type='submit'>
        {hasRequestedFinancialAdvisor
          ? 'Request Sent - Resend'
          : 'Email Financial Professional'}
      </Button>
    case 'plaidIncome':
      return <PlaidIncomeLinkButton disabled={disabled}/>
    case 'quiz':
      return <Button loading={loading} disabled={disabled} onClick={onClick} type='submit'>
      Take Test
      </Button>
    case 'plaidDocumentIncome':
      return <PlaidIncomeDocumentLinkButton disabled={disabled}/>
    default:
      return <Button loading={loading} disabled={disabled} onClick={onClick} type='submit'>
        Submit
      </Button>
    }
  }

  return (
    <div className='flex justify-end'>
      <div className='inline-flex'>
        {renderSubmitButton()}
      </div>
    </div>
  )
}

export default ButtonGroup
